<template>
  <div>
    <ReisenViewNav :reisekuerzel="reisekuerzel" />
    <router-view
      @reiseUpdated="onReiseUpdated"
      :isBusy="isBusy"
      :isInitialLoading="isInitiallyLoading"
      :reise="reise"
    ></router-view>
  </div>
</template>

<style lang="scss" scoped>
.tox-tinymce {
  height: 200px !important;
}
.more-btn {
  cursor: pointer;
}
</style>

<script>
import { mapState } from 'vuex';

import EventBus from '@/core/common/plugins/event-bus.js';
import { GET_REISE_DETAIL } from '@/core/produkte/reisen/stores/reisen.module';
import {
  SET_FULL_METADATA_HISTORY,
  SET_ACTIVE_SAISON_GUELTIG_AB,
} from '@/core/produkte/reisen/stores/reisen-metadata.module.js';

import ReiseViewDetailHeader from '@/components/produkte/reisen/reise-view-detail-header.vue';
import ReisenViewNav from '@/view/pages/reisen/reisen-view-nav.vue';
export default {
  name: 'ReiseViewComponent',
  components: {
    ReiseViewDetailHeader,
    ReisenViewNav,
  },
  props: {
    reisekuerzel: String,
  },
  watch: {
    reisekuerzel() {
      this.getReiseDetail();
    },
  },
  computed: {
    ...mapState({
      reise: state => state.reisen.reiseDetail,
      isBusy: state => state.reisen.isBusy,
      isInitiallyLoading: state => state.reisen.isInitiallyLoading,
    }),
  },
  beforeDestroy() {
    EventBus.$off('reload');
  },
  mounted() {
    this.getReiseDetail();
    EventBus.$on('reload', () => {
      this.getReiseDetail();
    });
  },
  methods: {
    onReiseUpdated() {
      this.getReiseDetail();
    },
    getReiseDetail() {
      this.$store.dispatch(GET_REISE_DETAIL, { reisekuerzel: this.reisekuerzel }).then(reise => {
        const { fullMetadataHistory } = reise;
        this.$store.commit(SET_FULL_METADATA_HISTORY, {
          fullMetadataHistory: fullMetadataHistory,
        });
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    document.title += ' / ' + to.params.reisekuerzel;
    next();
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === 'reisen-reisestammdaten-view') {
      this.$store.commit(SET_ACTIVE_SAISON_GUELTIG_AB, null);
    }
    document.title += ' / ' + to.params.reisekuerzel;
    next();
  },
};
</script>
